.PostCommentItem {
    padding: 1rem;

    &+.PostCommentItem {
        border-top: 1px solid #e9ecef;
    }

    .comment-header {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        margin-bottom: 1rem;
        position: relative;

        a {
            img {
                width: 3rem;
                height: 3rem;
                border-radius: 50%;
                object-fit: cover;
            }
        }

        .comment-user-info {
            font-size: .875rem;
            margin-left: .5rem;

            .nickname {
                font-weight: 600;
                color: #212529;
            }

            .date {
                margin-top: .125rem;
                color: #868e96;
                display: flex;
            }

            .modified {
                margin-left: .125rem;
            }
        }

        .comment-action-wrap {
            position: absolute;
            right: 0;
            top: .6rem;
            font-size: .875rem;
            color: #adb5bd;

            .comment-edit {
                font-size: .875rem;
                color: #bbbbbb;
                font-weight: 500;
                padding: .125rem .5rem;
                cursor: pointer;
            }

            .comment-delete-btn {
                font-size: .875rem;
                color: #bbbbbb;
                font-weight: 500;
                padding: .125rem .5rem;
                cursor: pointer;
            }
        }
    }

    // .comment-content {
    //     .MarkdownRender {
    //         p {
    //             font-family: Noto Serif KR, sans-serif;
    //         }
    //     }
    // }
}