.UserTemplate {
    height: 100%;

    main {
        width: 1024px;
        margin: 0 auto;
        margin-top: 4rem;
        margin-bottom: 4rem;
        padding-left: 1rem;
        padding-right: 1rem;
    }

    ul {
        padding: 0;
        list-style: none;
    }

    a {
        color: inherit;
    }
}