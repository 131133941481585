.ErrorInfo {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-direction: column;
    flex-direction: column;

    .error-code {
        font-size: 4rem;
        font-weight: 600;
    }

    .error-message {
        font-size: 2.5rem;
    }

    .error-home {
        margin-top: 2rem;

        button {
            width: 120px;
            text-align: center;
            font-size: 1.5rem;
            color: black;
            border: 1px solid #e3dede;
            padding: .8rem;
            border-radius: 6px;
            cursor: pointer;
            transition: all .125s ease-in;

            &:hover {
                color: #e3dede;
                background: black;

            }
        }
    }


}