.UserPostItem {
    list-style: none;
    padding: 25px 0 30px;
    border-bottom: 1px solid #eee;
    position: relative;
    overflow: hidden;
    width: 100%;

    .title {
        font-weight: normal;
        font-size: 1.3rem;
        letter-spacing: -1px;
        white-space: nowrap;

        &:hover {
            color: #e85a71;
        }
    }

    .description-wrap {
        padding-top: 0.2rem;

        .description {
            color: #666;
            font-size: 0.8rem;
            line-height: 1.5em;
            max-width: 520px;
        }
    }

    .date-wrap {
        padding-top: 0.4rem;

        .date {
            color: #959595;
            font-size: 0.7rem;
        }
    }


}