.Header {
    background: #fff;

    .header-wrap {
        display: flex;
        height: 7rem;
        align-items: center;
        padding-left: 2rem;
        padding-right: 2rem;

        .logo {
            font-size: 1.5rem;
            color: #333;
        }

        .header-right {
            margin-left: auto;
        }
    }


}