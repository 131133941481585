.SettingTemplate {
    height: 100%;

    main {
        width: 768px;
        margin: 0 auto;
        margin-top: 4rem;
    }

    ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    a {
        color: inherit;
    }
}