.CodeEditor {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    word-break: break-word;
    word-wrap: break-word;

    .title {
        background: #e85a71;
        border: none;
        font-size: 2rem;
        color: white;
        padding: 1rem;
        font-weight: 600;
        font-family: 'NanumSquare', sans-serif;
        outline: none;

        &::placeholder {
            color: rgba(255, 255, 255, 0.5)
        }
    }

    .code-editor {
        flex: 1;
        background: #272822;
        display: flex;
        flex-direction: column;

        .CodeMirror {
            font-size: 1rem;
            flex: 1;
            padding-left: 1rem;
            padding-right: 1rem;

            .cm-header-1 {
                font-size: 1.8rem;
            }

            .cm-header-2 {
                font-size: 1.4rem;
            }

            .cm-header-3 {
                font-size: 1.1rem;
            }

            .cm-header-4 {
                font-size: 1rem;
            }

            .cm-header-5 {
                font-size: 0.8rem;
            }

            .cm-header-6 {
                font-size: 0.5rem;
            }

            .cm-quote {
                font-style: oblique;
                color: #bbbbbb;
            }

            .CodeMirror-placeholder {
                color: #6d747b;
            }
        }
    }
}