.LoginRightPane {
    flex: 1.4;
    height: 100%;

    .right {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        padding-right: 8rem;
        padding-left: 8rem;
        background: #f1f3f5;


        .login-box {
            background: #fff;
            padding: 3rem;
            width: 600px;

            .login-title {
                margin: 0;
                font-size: 1.5rem;
                margin-bottom: 1rem;
            }

            .login-email {
                margin-top: 0.3rem;

                input {
                    width: 100%;
                    border: 1px solid #dee2e6;
                    border-radius: 6px;
                    padding: 1rem;
                    font-size: 1rem;
                    box-sizing: border-box;
                }
            }

            .login-pwd {
                margin-top: 0.3rem;

                input {
                    width: 100%;
                    border: 1px solid #dee2e6;
                    border-radius: 6px;
                    padding: 1rem;
                    font-size: 1rem;
                    box-sizing: border-box;
                }
            }

            .login-button-box {
                margin-top: 1rem;

                button {
                    width: 100%;
                    border-radius: 4px;
                    background: #454552;
                    color: #fff;
                    font-size: 1rem;
                    padding: 1rem;
                    cursor: pointer;
                    text-align: center;
                    transition: all .125s ease-in;

                    &:hover {
                        background: #303038
                    }
                }
            }
        }


    }
}