.TagTemplate {
    height: 100%;

    main {
        background: #fbfbfb;
        height: 100%;
    }

    ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    a {
        color: inherit;
    }
}