.UserMenu {

    .user-menu-wrap {
        position: absolute;
        right: 16px;
        top: 80px;
        background: #fff;
        border-radius: 6px;
        box-shadow: 0 3px 6px rgba(31, 29, 29, 0.016), 0 3px 6px rgba(0, 0, 0, 0.023);
        z-index: 9;
        padding: 0;
        margin: 0;
        border: 1px solid rgba(31, 29, 29, 0.016);

        .user-menu-item {
            margin: 0;
            padding: 0;
            min-width: 12rem;
            padding: 1rem;
            cursor: pointer;
            list-style: none;
            color: #000000;


            &:hover {
                color: #e85a71;
            }
        }
    }
}