.PostLikes {
    position: absolute;
    width: 4rem;
    right: -7rem;
    top: 5.5rem;
    padding-top: 1rem;

    .likes-wrap {
        border-radius: 2rem;
        padding: .5rem;
        background: #ffffff;
        color: #cccccc;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column;
        -ms-flex-align: center;
        align-items: center;
        box-shadow: 0 3px 6px rgba(31, 29, 29, 0.1), 0 3px 6px rgba(0, 0, 0, 0.023);
        cursor: pointer;

        .like-btn {
            width: 3rem;
            height: 3rem;
            border-radius: 1.5rem;
            cursor: pointer;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-align: center;
            align-items: center;
            -ms-flex-pack: center;
            justify-content: center;
            font-size: 2rem;
        }

        .like-count {
            font-size: .9rem;
            margin-top: -.7rem;
        }

        &:hover {
            color: #e85a71;
        }
    }

    .liked {
        color: #e85a71;
    }

    .fixed {
        position: fixed;
        top: 1rem;
    }
}