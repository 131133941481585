.PostCommentInput {

    textarea {
        width: 100%;
        outline: none;
        font-size: .9rem;
        padding: 1rem;
        border: 1px solid #e9ecef;
        border-radius: 4px;
        resize: none;
        color: #454552;
        display: block;
        line-height: 1.5rem;
        max-height: 400px;
        overflow: hidden;
        box-sizing: border-box;

    }

    .comment-btn {
        margin-top: 1rem;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: end;
        justify-content: flex-end;

        button {
            padding: .5rem;
            background: #454552;
            border-radius: 6px;
            color: #fff;
            cursor: pointer;
            transition: all .125s ease-in;

            &:hover {
                background: #e85a71;
            }
        }

        .comment-edit-cancel {
            margin-left: .5rem;
        }
    }
}