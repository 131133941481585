.LoginLeftPane {
    flex: 1;
    height: 100%;

    .left {
        color: #fff;
        background: #454552;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-pack: center;
        justify-content: center;
        height: 100%;
        padding-right: 8rem;
        padding-left: 8rem;

        .logo {
            font-size: 2.5rem;
            font-weight: 500;
        }

        h2 {
            margin: 0;
            font-size: 2rem;
            margin-top: 4rem;
        }

        p {
            font-size: 1.5rem;
            margin-bottom: 4rem;
            min-width: 24rem;
        }

    }
}