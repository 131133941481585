.PostTags {
    a {
        margin: .375rem;
        display: -ms-inline-flexbox;
        display: inline-flex;
        background: #f8f9fa;
        border: 1px solid #dee2e6;
        color: #495057;
        padding: .5rem;
        font-size: .75rem;
        font-weight: 500;
        line-height: .75rem;
        border-radius: 4px;
    }
}