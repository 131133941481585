.EditorHeader {
    background: #fff;
    height: 4rem;
    padding-left: 1rem;
    padding-right: 1rem;
    display: flex;
    align-items: center;
    position: relative;
    border-bottom: 1px solid #ccc;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.05);

    .back {
        color: #454552;
        display: flex;
        border-radius: 6px;
        align-items: center;
        cursor: pointer;

        svg {
            margin-right: .4rem;
            font-size: 1.1rem;
        }
    }

    .logo {
        font-size: 1.3rem;
        color: #333;
        // font-weight: 300;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;


    }

    .editor-btns {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        position: absolute;
        right: 1rem;

        .image-upload-btn {
            display: flex;
            align-items: center;
            border: 1px solid #454552;
            padding: .6rem;
            border-radius: 4px;
            color: #454552;
            cursor: pointer;
            margin-right: 1rem;
            line-height: 0.5rem;
            transition: all .125s ease-in;


            svg {
                margin-right: .4rem;
                font-size: 1.4rem;
            }

            &:hover {
                background: #454552;
                color: #fff;
            }
        }

        .submit {
            color: #fff;
            background: #454552;
            border-radius: 6px;
            transition: all .125s ease-in;

            &:hover {
                background: #e85a71;
            }
        }
    }

}



// 색조합 참고 사이트 https://color.adobe.com/ko/search?q=editor
//https://nigrum.tistory.com/286