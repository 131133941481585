.UserInfo {
    position: relative;
    margin: 0 auto 2rem;
    padding: 3rem 0 0;

    .thumbnail {
        overflow: hidden;
        position: absolute;
        top: -50px;
        right: 0;
        width: 100px;
        height: 100px;
        background-position: -250px -80px;

        img {
            width: 100px;
            height: 100px;
            border-radius: 100px;
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.016), 0 3px 6px rgba(0, 0, 0, 0.023);
            object-fit: cover;
        }
    }

    .user-info {
        .nickname {
            display: block;
            padding-right: 170px;
            font-weight: 400;
            font-size: 28px;
            line-height: 34px;
        }

        .desc-nickname {
            padding: 5px 180px 0 0;
            font-size: 13px;
            line-height: 20px;
            color: #959595;
            word-wrap: break-word;
            word-break: break-word;
            white-space: pre-line;
            margin-bottom: -1px;
        }
    }

    .blog-info {
        display: flex;
        color: #959595;
        margin-top: 1rem;

        .count-post {
            max-width: 4rem;

            .post-txt {
                display: flex;
                font-size: 0.9rem;
                font-weight: 300;
            }

            .post-count {
                display: flex;
                font-size: 1.2rem;
                font-weight: 300;
            }

        }

    }
}