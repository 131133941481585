.PostItem {
    // width: calc(25% - 1.75rem);
    margin: .875rem;
    width: calc((100% - 8.75rem) / 5);
    position: relative;
    background: #fff;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    -webkit-box-shadow: 0 0 4px rgba(0, 0, 0, .05);
    box-shadow: 0 0 4px rgba(0, 0, 0, .05);
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;


    .thumbnail {
        width: 100%;
        padding-top: 52.63%;
        position: relative;
        display: block;

        img {
            object-fit: cover;
            display: block;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            background: rgb(222, 226, 230);

        }
    }

    .post-content {
        padding: 1rem;
        min-height: 4rem;

        .title {
            display: block;
            margin: 0;
            font-size: 1.25rem;
            line-height: 2rem;
            max-height: 4rem;
            font-weight: 500;
            overflow: hidden;
        }
    }

    .post-info {
        padding: 1rem;
        display: flex;
        color: #bbbbbb;
        font-size: 0.8rem;
        letter-spacing: 0rem;

        .nickname {
            padding-right: 0.5rem;
        }

        .date {
            padding-right: 0.5rem;
        }

    }

    .line {
        height: 1px;
        background: #e7edf3;
    }

    .post-bottom {
        padding: 1rem;
        min-height: 4rem;

        .description {
            margin-bottom: 1rem;
            max-height: 3rem;
            overflow: hidden;
            color: #666666;
        }

    }
}

.noThumb {
    .post-bottom {
        .description {
            max-height: 18rem;
        }
    }
}


@media (max-width: 1920px) {
    .PostItem {
        width: calc(25% - 1.75rem);
    }
}

@media (max-width: 1600px) {
    .PostItem {
        width: calc(33.333% - 1.75rem);
    }
}

@media (max-width: 1200px) {
    .PostItem {
        width: calc(50% - 1.75rem);
    }
}

@media (max-width: 1024px) {
    .PostItem {
        width: calc(100% - 1.75rem);
        margin-top: 1.25rem;
        margin-bottom: 1.25rem;
    }
}