.PostHeader {

    .title {
        text-align: left;
        margin: 0;
        font-size: 2.8rem;
        font-weight: 500;
        font-family: Noto Serif KR, sans-serif;
    }

    .post-info {
        display: flex;
        color: #bbbbbb;
        font-size: 0.8rem;
        margin-top: 1.5rem;

        .nickname {
            padding-left: 0.5rem;
            padding-right: 0.5rem;
        }
    }

    .line {
        height: 1px;
        background: #e7edf3;
        margin-top: 2rem;
        margin-bottom: 3rem;
    }

    .post-action-wrap {
        margin-right: -.4rem;
        margin-top: -2rem;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: end;
        justify-content: flex-end;
        margin-bottom: 1rem;

        .post-edit {
            font-size: .875rem;
            color: #bbbbbb;
            font-weight: 500;
            padding: .125rem .5rem;
        }

        .post-delete-btn {
            font-size: .875rem;
            color: #bbbbbb;
            font-weight: 500;
            padding: .125rem .5rem;
            cursor: pointer;
        }
    }
}