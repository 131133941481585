.InputTags {
    .input-tag {
        width: 100%;

        input {
            font-size: .9rem;
            width: 100%;
            outline: none;
            padding-left: .5rem;
            padding-top: .5rem;
            padding-bottom: .5rem;
        }
    }

    .tags {
        margin-top: .5rem;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin-left: -.25rem;
        margin-right: -.25rem;
        // max-height: 5.5rem;
        overflow-y: auto;
        position: relative;

        overflow: hidden !important;
        overflow-anchor: none;
        -ms-overflow-style: none;
        touch-action: auto;
        -ms-touch-action: auto;

        .tag {
            margin: .25rem;
            padding: .25rem;
            padding-left: .5rem;
            padding-right: 0;
            font-size: .85rem;
            color: #212529;
            -ms-flex-align: center;
            align-items: center;
            display: -ms-flexbox;
            display: flex;
            background: #a7d0e9;
            font-weight: 500;
            word-break: break-all;
            border-radius: 2px;

            .tag-remove {
                font-size: .9rem;
                color: #212529;
                padding-left: .25rem;
                padding-right: .25rem;

                svg {
                    cursor: pointer;
                    color: #454552;

                    &:hover {
                        color: #c92a2a;
                    }
                }
            }
        }
    }
}