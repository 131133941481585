.PostContent {
    margin-top: 2rem;
    margin-bottom: 4rem;

    .thumbnail {
        margin-top: 2rem;
        margin-bottom: 2rem;

        img {
            width: 100%;
            height: auto;
        }
    }
}