.SettingProfile {
    display: flex;

    .thumbnail-wrap {
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column;
        margin-right: 1rem;
        width: 12rem;

        img {
            width: 12rem;
            height: 12rem;
            display: block;
            border-radius: 6rem;
            -o-object-fit: cover;
            object-fit: cover;
            margin-bottom: 1rem;
        }

        .change-thumbnail-btn {
            background: #454552;
            border-radius: 6px;
            text-align: center;
            padding: .8rem;
            color: #ffffff;
            width: 100%;
            cursor: pointer;
            transition: all .125s ease-in;

            &:hover {
                background: #e85a71;
            }
        }
    }

    .user-info {
        padding-left: .5rem;
        -ms-flex: 1 1;
        flex: 1 1;
        color: #495057;

        .nickname {
            font-size: 1.5rem;
            font-weight: 600;
            margin: 0;
        }
    }


}