.TagItem {
    display: inline-flex;
    padding: .5rem 1rem;
    margin: .375rem;
    background: #fff;
    border: 1px solid #ced4da;
    color: #343a40;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 1.0625rem;
    cursor: pointer;

    .tag-name {
        font-size: .875rem;
        font-weight: 500;
    }

    .post-count {
        margin-left: .5rem;
        color: #868e96;
        font-size: .625rem;
    }
}