.PostTemplate {
    height: 100%;
    margin: 0 auto;
    width: 768px;
    position: relative;
    font-family: 'Noto Serif KR', serif !important;

    a {
        color: inherit;
    }
}