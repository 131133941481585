.UploadThumbnail {
    text-align: center;
    margin-top: 1rem;

    .thumbnail-upload-btn {
        width: 100%;
        padding: .5rem;
        font-size: 1.1rem;
        background: #454552;
        color: #ffffff;
        cursor: pointer;
        border-radius: 10px;
        letter-spacing: 1px;
        transition: all .125s ease-in;

        &:hover {
            background: #e85a71;
        }
    }

    .thumbnail-preview {
        .thumbnail-remove {
            margin-top: -.75rem;
            text-align: right;
            padding-right: .25rem;
            margin-bottom: .5rem;

            button {
                font-size: .875rem;
                font-weight: 800;
                color: #454552;
                cursor: pointer;

                &:hover {
                    color: #e85a71;
                }
            }
        }


        .thumbnail-wrap {
            width: 100%;
            padding-top: 52.63%;
            position: relative;

            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            .change-thumbnail {
                display: -ms-flexbox;
                display: flex;
                -ms-flex-align: end;
                align-items: flex-end;
                -ms-flex-pack: end;
                justify-content: flex-end;
                -ms-flex-direction: column;
                flex-direction: column;
                padding-right: .875rem;
                padding-bottom: .875rem;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;



                svg {
                    margin-right: .4rem;
                    font-size: 1.2rem;
                }

                button {
                    cursor: pointer;
                    border-radius: 4px;
                    padding: .5rem .75rem;
                    background: #fff;
                    color: #343a40;
                    display: -ms-flexbox;
                    display: flex;
                    -ms-flex-align: center;
                    align-items: center;
                    transition: all .125s ease-in;

                    &:hover {
                        background: #343a40;
                        color: #fff;
                    }
                }
            }
        }
    }
}